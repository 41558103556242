import React from 'react';
import advocateProfilePic from '../../../assets/images/advocates-details/kruthika.jpg';

const AdvocateKruthikaDetails = () => {
  return (
    <section className='team-details'>
      <div className='container'>
        <div className='row justify-content-between'>
          <div className='col-lg-5'>
            <div className='team-details__content'>
              <h2 className='team-details__title'>Read my story</h2>
              <p className='team-details__text'>
                Ms. Kruthika Kandhala graduated with a BBA LLB degree from
                Symbiosis Law School, Symbiosis International University. She
                also holds a diploma in Media Laws from NALSAR. With a passion
                for legal research and writing, she has authored multiple
                research papers and articles spanning various legal domains. Her
                professional trajectory includes internships at prestigious law
                firms across India and association with the offices of reputed
                senior counsels. Having enrolled as an Advocate in 2023, she
                specialises in Civil Law, Corporate & Commercial Laws, and
                Arbitration. Ms. Kruthika actively practises at the High Court
                of Telangana and Civil Courts.
              </p>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='team-one__single'>
              <div className='team-one__image'>
                <img src={advocateProfilePic} alt='' />
              </div>
              <div className='team-one__content'>
                <h2 className='team-one__name'>
                  Ms. Kruthika Kandhala. BBA LLB
                </h2>
                <p className='team-one__designation'>Advocate</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdvocateKruthikaDetails;
