import AdvocateKruthikaDetails from '../../components/about/advocates-details/kruthika';
import Footer from '../../components/common/footer';
import Layout from '../../components/common/layout';
import NavigationMenu from '../../components/common/navigationMenu';
import PageHeader from '../../components/common/pageHeader';
import React from 'react';

const AdvocateKruthikaDetailsPage = () => {
  return (
    <Layout pageTitle='Avadhani & Associates | Kruthika Kandhala'>
      <NavigationMenu active='about' />
      <PageHeader
        title='Kruthika Kandhala'
        subMenu='Advocates'
        backLink='/about#team-section'
      />
      <AdvocateKruthikaDetails />
      <Footer />
    </Layout>
  );
};

export default AdvocateKruthikaDetailsPage;
